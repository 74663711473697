import React, { useContext, useState, useEffect } from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import PageWrapper from "../../components/PageWrapper";
import GlobalContext from "../../context/GlobalContext";
import { getLastSixMonths, addDays } from "../../utils/addDays"
import { AdminUsers } from "../../utils/apiCalls";
import { GetAdminDashboardCount } from "../../utils/GetAPIUtils";

import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';

export default function DashboardMain() {
    const gContext = useContext(GlobalContext);
    const [adminDashboardCount, setAdminDashboardCount] = useState([]);
    const [totalClients, setTotalClients] = useState(null);
    const [totalExperts, setTotalExperts] = useState(null);
    const [totalActiveContracts, setTotalActiveContracts] = useState(null);
    const [totalActiveUsers, setTotalActiveUsers] = useState(null);
    const [totalFlaggedUsers, setTotalFlaggedUsers] = useState(null);
    const [totalJobs, setTotalJobs] = useState(null);
    const [totalOffers, setTotalOffers] = useState(null);
    const [totalProposals, setTotalProposals] = useState(null);
    const [expertData, setexpertData] = useState([]);
    const [clientData, setclientData] = useState([]);
    const [userCount, setUserCount] = useState([]);
    const [tabValue, setTabValue] = useState('total');
    const xLabels = getLastSixMonths(new Date());
    function getMonthYear(date) {
        const lastSixMonth = new Date(date?.toString()?.split("T")[0]);
        const monthName = lastSixMonth.toLocaleString("default", { month: "short" });
        return `${monthName} ${lastSixMonth.getFullYear()}`;
    }
    async function LoadUsers() {
        try {
            gContext.setLoaderModalVisible(true);
            let expert = [];let client=[];
            const result = await AdminUsers("all", 1);
            if (result.data.adminUsers && result.data.adminUsers.responseData.code == "200") {
                expert = JSON.parse(result.data.adminUsers.responseData.message);

            }
            const result1 = await AdminUsers("all", 2);
            if (result1.data.adminUsers && result1.data.adminUsers.responseData.code == "200") {
                client = JSON.parse(result1.data.adminUsers.responseData.message);
            }
            setUserCount([
                { id: 0, value: client.length, label: 'Clients (' + client.length+')'  },
                { id: 1, value: expert.length, label: 'Experts (' + expert.length + ')' },
                
            ])
            let expertValue = [];
            let clientValue = [];

            xLabels.forEach((value) => {
                let sdata = expert.filter(item => getMonthYear(item["datecreated"]) == value);
                expertValue.push(sdata.length);
            });
            setexpertData(expertValue);

            xLabels.forEach((value) => {
                let sdata = client.filter(item => getMonthYear(item["datecreated"]) == value);
                clientValue.push(sdata.length);
            });
            setclientData(clientValue);
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log(e);
        }
    }
    async function getUtils() {

        try {
            gContext.setLoaderModalVisible(true);
            const response = await GetAdminDashboardCount();

            if (response.data && response.data.adminDashboardCount) {

                setAdminDashboardCount(response.data.adminDashboardCount);
                setTotalClients(response.data.adminDashboardCount.totalClients);
                setTotalExperts(response.data.adminDashboardCount.totalExperts);
                setTotalActiveUsers(response.data.adminDashboardCount.totalActiveUsers);
                setTotalActiveContracts(response.data.adminDashboardCount.totalActiveContracts);
                setTotalFlaggedUsers(response.data.adminDashboardCount.totalFlaggedUsers);
                setTotalJobs(response.data.adminDashboardCount.totalJobs);
                setTotalOffers(response.data.adminDashboardCount.totalOffers);
                setTotalProposals(response.data.adminDashboardCount.totalProposals);

                gContext.setLoaderModalVisible(false);
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);
    }

    useEffect(() => {
        document.title = "Dashboard | Syncuppro";
        getUtils();
        LoadUsers();

    }, []);
    return (
        <>
            <PageWrapper
                headerConfig={{
                    button: "profile",
                    isFluid: true,
                    bgClass: "bg-default",
                    reveal: false,
                }}
            >

                <div className="dashboard-main-container mt-15 mt-lg-22 min-h-100vh">
                    <div className="container">
                        <div className="row mb-7">

                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6" >
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-user"></i>
                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalClients) ? totalClients : 0}
                                            </span>

                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Clients
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-user-tie">  </i>
                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalExperts) ? totalExperts : 0}
                                            </span>

                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Experts

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-red bg-red-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-flag"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalFlaggedUsers) ? totalFlaggedUsers : 0}
                                            </span>


                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Flagged
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-green bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-briefcase"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalJobs) ? totalJobs : 0}
                                            </span>


                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Jobs
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>


                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-file-contract"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalActiveContracts) ? totalActiveContracts : 0}
                                            </span>

                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Active Contracts
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-users"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalActiveUsers) ? totalActiveUsers : 0}
                                            </span>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Verified Users
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-briefcase"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalProposals) ? totalProposals : 0}
                                            </span>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Proposals
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-green bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                        <i className="fas fa-file-contract"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span className="counter">
                                                {(totalOffers) ? totalOffers : 0}
                                            </span>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Offers
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>


                        </div>
                        <div className="mb-10"><h5>Registered Users</h5></div>
                        <div className="row bg-white p-0 m-0 mt-10">
                            <div className="col-12  bg-athens  p-0 m-0">
                                <div className="header-btns ml-5  d-xs-flex ">
                                    <div className="pl-0  " >

                                        <a
                                            className={`btn ${(tabValue == 'total') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault(); 
                                                setTabValue('total');
                                            }}
                                        >
                                            Total Users
                                        </a>
                                        <a
                                            className={`btn ${(tabValue == 'month') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault(); 
                                                setTabValue('month');

                                            }}
                                        >
                                            Monthly Users
                                        </a>




                                    </div>

                                </div>
                            </div>
                        </div>

                        {tabValue == "total" && <div style={{ width: "380",height:"500px", overflowX: "auto", borderRadius: '10px' }} className="border p-10">   <PieChart
                            series={[
                                {
                                    data: userCount
                                },
                            ]} loading={userCount.length == 0}
                            width={350}
                            height={420} slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'left' },
                                    padding: 0,
                                },
                            }}
                            colors={['#91AE28', '#F7921E']}
                        /></div>}
                        {tabValue == "month" && <div style={{ width: "380", height: "500px", overflowX: "auto", borderRadius: '10px' }} className="border p-10">  <BarChart
                            width={1050}
                            height={350} loading={expertData.length == 0}
                            slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'left' },
                                    padding: 10,
                                },
                            }}
                            series={[
                                { data: expertData, label: 'Expert', id: 'pvId' },
                                { data: clientData, label: 'Client', id: 'uvId' },
                            ]}
                            xAxis={[{ data: xLabels, scaleType: 'band', }]}
                            yAxis={[{ label: "Users Count" }]}
                            colors={['#F7921E', '#91AE28']}
                        /></div>}
                        
                        <div className="mt-10" style={{ height: "50px" }}>&nbsp;</div>
                        <div className="accordion rounded-10 border  pl-1  mt-10">
                        </div>




                    </div>
                </div>
            </PageWrapper>
        </>
    );
};